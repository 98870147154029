<template>
  <b-navbar-nav class="nav">
    <b-nav-item-dropdown link-classes="bookmark-star" lazy>
      <feather-icon
        slot="button-content"
        icon="StarIcon"
        size="21"
        class="text-warning"
        @click="() => {
          showFav();
        }"
      />

      <!-- Dropdown Content -->
      <li style="min-width: 300px">
        <vue-perfect-scrollbar
          class="search-list search-list-bookmark scroll-area"
          tagname="ul"
        >
          <b-dropdown-item
            v-for="(suggestion, index) in FavMenu"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            @click="() => {
              $router.push({ name: suggestion.routeUrl });
            }"
          >
            <!-- <feather-icon :icon="suggestion.icon" class="mr-75" size="18" /> -->
            <span class="align-middle">{{ $t(suggestion.title) }}</span>
            <feather-icon
              icon="StarIcon"
              class="ml-auto text-warning fill-current"
              size="16"
            />
            <!-- <h4 v-if="!FavMenu">No Results Found.</h4> -->
          </b-dropdown-item>
          <b-dropdown-item v-show="!FavMenu.length" disabled class="text-center">
            {{$t('pleaseSelectFavMenu')}}
          </b-dropdown-item>
        </vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
// import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest';
// import { ref, watch } from '@vue/composition-api';
// import router from '@/router';
// import store from '@/store';
// import searchAndBookmarkData from '@core/layouts/components/app-navbar/search-and-bookmark-data';

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      FavMenu: [],
    };
  },
  // computed: {
  //   FavMenu() {
  //     return this.favMenu;
  //   },
  // },
  mounted() {
   this.FavMenu = localStorage.favMenu ? JSON.parse(localStorage.favMenu) : [];
  },
  methods: {
    showFav() {
      this.FavMenu = localStorage.favMenu ? JSON.parse(localStorage.favMenu) : [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
