export const reportsRoutes = [
  {
    title: 'students-reports',
    route: 'students-reports',
  },
  {
    title: 'employees-reports',
    route: 'employees-reports',
  },
  {
    title: 'accounts-reports',
    route: 'accounts-reports',
  },
  {
    title: 'transaction-reports',
    route: 'transaction-reports',
  },
  {
    title: 'items-reports',
    route: 'items-reports',
    isEnabledStore: true,
  },
];
export const reportsPermissions = [
  {
    permissions: ['reviewStudentsReports', 'reviewStudentsReportsServices', 'reviewStudentsReportsDiscounts', 'reviewAccountsStudentreports', 'reviewStudentStatusesReport', 'reviewStudentSuccessReport'],
    route: { title: 'students-reports', route: 'students-reports' },
  },
  {
    permissions: ['viewEmployeesData', 'viewEmployeesPayslip', 'viewEmployeesBenefits', 'EmployeeDeductions'],
    route: { title: 'employees-reports', route: 'employees-reports' },
  },
  {
    permissions: ['taxesReport', 'reviewAccountStatementDetailsReport', 'reviewTrialBalanceReport', 'reviewIncomeStatementReport', 'reviewBankBalanceReport', 'bankAccountStatment', 'reviewCashBoxAccountStatementReport', 'incomeTransaction', 'expenseTransaction', 'balanceSheetReport', 'reviewSuppliersExpensesReport'],
    route: { title: 'accounts-reports', route: 'accounts-reports' },
  },
  {
    permissions: ['reviewCollectionTransactionsReport', 'reviewPaymentTransactionsReport', 'reviewReportFinancialTransactionAllocation', 'reviewIncomesReport', 'reviewExpensesReport', 'reviewReportSalesAllocation'],
    route: { title: 'transaction-reports', route: 'transaction-reports' },
  },
  {
    permissions: ['reviewInventoryReport', 'reviewStoreBalanceReport', 'reviewItemTransactionsTotalsReport', 'reviewItemTransactionsDetailsReport', 'reviewStoreEvalutionReport'],
    route: { title: 'items-reports', route: 'items-reports' }
  },
];
