<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <bookmarks />
      <Breadcrumb />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <easy-add /> -->
      <branch />
      <b-dropdown-divider />
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <FiscalYears />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue';
import Bookmarks from './Bookmarks.vue';
import Locale from './Locale.vue';
import Branch from './Branch.vue';
// import SearchBar from './SearchBar.vue';
import DarkToggler from './DarkToggler.vue';
// import CartDropdown from './CartDropdown.vue'
// import easyAdd from './easyAdd.vue';
import UserDropdown from './UserDropdown.vue';
import Breadcrumb from '../../@core/layouts/components/AppBreadcrumb.vue'
// import EasyAdd from './easyAdd.vue';
import FiscalYears from './FiscalYears.vue';

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    Branch,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
    // easyAdd,
    UserDropdown,
    Breadcrumb,
    // EasyAdd
    FiscalYears,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    FavMenu() {
      return JSON.parse(localStorage.favMenu);
    },
  },
};
</script>
