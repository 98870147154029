<template>
  <p class="app-footer clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://plugin-soft.com/"
        target="_blank"
      >EDUPRO-SCHOOL-MANAGEMENT</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>

<style>
@media (max-width: 768px) {
  .app-footer span {
    font-size: small;
  }
}
</style>
